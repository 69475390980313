var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('b-breadcrumb', {
    staticClass: "breadcrumb-chevron"
  }, [_c('b-breadcrumb-item', {
    attrs: {
      "to": {
        name: 'paket-selengkapnya'
      }
    }
  }, [_vm._v(" Paket ")]), _c('b-breadcrumb-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.data.name) + " ")])], 1)], 1), _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2 mb-md-0",
    attrs: {
      "md": "6",
      "xl": "3"
    }
  }, [_c('b-img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": _vm.data.thumbnail != null ? _vm.apiFile + _vm.data.thumbnail : require('@/assets/images/elp/no-image.jpg')
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "9"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "xl": "9"
    }
  }, [_c('h2', {
    staticClass: "text-left"
  }, [_c('b', [_vm._v(_vm._s(_vm.data.name))])])]), _c('b-col', {
    staticClass: "mb-2 mb-xl-0",
    attrs: {
      "lg": "12",
      "xl": "3"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UsersIcon"
    }
  })], 1), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.data.sum_quota) + " Berlangganan")])])], 1)], 1), _c('b-row', [_c('b-col', [_c('p', {
    staticClass: "text-dark text-left"
  }, [_vm._v(" " + _vm._s(_vm.data.description ? _vm.data.description : "Tidak ada deskripsi") + " ")])])], 1), _c('b-row', {
    staticClass: "mt-1 align-items-center"
  }, [_vm.is_paket_purchased == 0 ? _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "3"
    }
  }, [_c('h2', {
    staticClass: "text-left"
  }, [_c('b', [_vm._v("Rp. " + _vm._s(_vm.formatRupiah(_vm.data.price)))])])]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6",
      "xl": "4"
    }
  }, [_vm.is_paket_purchased == 0 ? _c('b-button', {
    staticClass: "float-left",
    attrs: {
      "variant": "primary",
      "to": {
        name: 'payment',
        params: {
          id: _vm.data.slug
        }
      }
    }
  }, [_vm._v(" Langganan ")]) : _c('b-button', {
    staticClass: "float-left disabled",
    attrs: {
      "variant": "success",
      "disabled": ""
    }
  }, [_vm._v(" Sudah Dibeli ")])], 1)], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('p', {
    staticClass: "text-left text-dark"
  }, [_vm._v(" *Langganan paket ini aktif untuk 1 tahun. Berhenti berlangganan kapan saja. ")])])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Subtest",
      "header-bg-variant": "primary",
      "header-text-variant": "white"
    }
  }, [_vm.dataSubtes[0] != null ? _c('app-collapse', {
    staticClass: "mt-1"
  }, _vm._l(_vm.dataSubtes, function (item, i) {
    return _c('div', {
      key: i
    }, [item.title ? _c('app-collapse-item', {
      attrs: {
        "title": item.title
      }
    }, [_c('ul', {
      staticStyle: {
        "padding": "0"
      }
    }, _vm._l(item.items, function (items, i) {
      return _c('li', {
        key: i,
        staticClass: "mt-1"
      }, [_c('b', [_vm._v(_vm._s(items.title))]), _c('br'), _c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ClockIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(items.timer) + " Menit")]), _c('feather-icon', {
        staticClass: "mr-50 ml-2",
        attrs: {
          "icon": "FileTextIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(items.sum_soal) + " Soal")])], 1);
    }), 0)]) : _c('ul', {
      staticClass: "list-unstyled"
    }, [_c('b-media', {
      attrs: {
        "tag": "li"
      },
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-img', {
            attrs: {
              "rounded": "circle",
              "blank": "",
              "blank-color": "#26292d",
              "width": "10",
              "alt": "placeholder"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('h5', {
      staticClass: "mt-0"
    }, [_c('b', [_vm._v(_vm._s(item.title))])]), _c('p', {
      staticClass: "mb-2"
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-secondary"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('span', {
      staticClass: "ml-25"
    }, [_vm._v(_vm._s(item.timer) + " menit")])], 1), _c('b-badge', {
      staticClass: "ml-25",
      attrs: {
        "variant": "light-secondary"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "ClipboardIcon"
      }
    }), _c('span', {
      staticClass: "ml-25"
    }, [_vm._v(" " + _vm._s(item.sum_soal) + " Soal ")])], 1)], 1)])], 1)], 1);
  }), 0) : _c('div', [_c('h5', {
    staticClass: "text-danger text-center mt-2"
  }, [_vm._v(" Tidak ada Subtest ")])])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Keuntungan",
      "header-bg-variant": "primary",
      "header-text-variant": "white"
    }
  }, [_vm.data.advantages[0] != null ? _c('div', _vm._l(_vm.data.advantages, function (item, i) {
    return _c('p', {
      key: i,
      staticClass: "mt-2"
    }, [_c('feather-icon', {
      staticClass: "mr-25 text-success",
      attrs: {
        "icon": "CheckCircleIcon",
        "size": "24"
      }
    }), _c('span', {
      staticClass: "ml-25"
    }, [_vm._v(_vm._s(item.text))])], 1);
  }), 0) : _c('div', [_c('h5', {
    staticClass: "text-danger text-center mt-2"
  }, [_vm._v("Tidak ada")])])]), _c('b-card', {
    attrs: {
      "header": "Pertanyaan Umum",
      "header-bg-variant": "primary",
      "header-text-variant": "white"
    }
  }, [_vm.data.faqs[0] != null ? _c('div', [_c('app-collapse', {
    staticClass: "mt-1",
    attrs: {
      "type": "margin"
    }
  }, _vm._l(_vm.data.faqs, function (faq, i) {
    return _c('app-collapse-item', {
      key: i,
      attrs: {
        "title": faq.question
      }
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(faq.answer)
      }
    })]);
  }), 1)], 1) : _c('div', [_c('h5', {
    staticClass: "text-danger text-center mt-2"
  }, [_vm._v("Tidak ada")])])])], 1)], 1)], 1)], 1)]), _c('footer-home')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }